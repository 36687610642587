<template>
    <div :class="{'timepicker-inline-row': inline, 'timepicker-inline': label}">
        <div
            v-if="label"
            class="timepicker-inline-label text-nowrap"
        >
            {{ label }}
        </div>
        <div class="timepicker-inline-row">
            <div class="timepicker-inline-select">
                <v-select
                    v-model="hours"
                    :items="hoursItems"
                    @change="change()"
                    class="pt-0"
                    hide-details
                />
            </div>
            <div class="timepicker-inline-legend">
                h
            </div>
            <div class="timepicker-inline-separator">
                :
            </div>
            <div class="timepicker-inline-select">
                <v-select
                    v-model="minutes"
                    :items="minutesItems"
                    @change="change()"
                    class="pt-0"
                    hide-details
                />
            </div>
            <div class="timepicker-inline-legend">
                m
            </div>
        </div>
    </div>
</template>
<style>
    .timepicker-inline {
        padding-top: 12px;
    }

    .timepicker-inline-row {
        display: flex;
        align-items: center;
    }

    .timepicker-inline-label {
        font-size: 14px;
        min-width: 90px;
    }

    .timepicker-inline-separator {
        padding: 0 10px;
    }

    .timepicker-inline-legend {
        margin-left: 5px;
    }

    .timepicker-inline-select {
        width: 100%;
    }
</style>
<script>
    export default {
        name: 'timepicker-inline',
        props: ['label', 'value', 'callback', 'inline'],
        data() {
            return {
                hours: '',
                minutes: '',
                hoursItems: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
                minutesItems: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'],
            }
        },
        watch: {
            value() {
                this.setValue()
            }
        },
        beforeMount: function () {
            this.setValue()
        },
        methods: {
            setValue: function () {
                if (this.value) {
                    const split = this.value.split(':')
                    this.hours = split[0]
                    this.minutes = split[1]
                }
            },
            change: function () {
                const value = this.hours && this.minutes ? (this.hours + ':' + this.minutes) : ''
                this.callback(value)
            },
        },
    }
</script>